import { Box, Image, Link } from "@chakra-ui/core";
import moment from "moment";
import React from "react";
import Countdown from "react-countdown";
import Container from "./Container";
import ContentfulRichText from "./ContentfulRichText";

interface Props {
  startDate: string;
  endDate: string;
  heroText: {
    json: string;
  };
  heroImage: {
    fixed: {
      src: string;
    };
  };
  heroBkg: {
    fixed: {
      src: string;
    };
  };
}

const Hero: React.FC<Props> = ({ heroText, heroImage, heroBkg, startDate, endDate }) => {
  return (
    <Box className="frogid-week-hero" position="relative">
      <Container className="frogid-week-hero__inner">
        <Box className="frogid-week-hero__info">
          <Box className="frogid-week-hero__text">
            <ContentfulRichText content={heroText} />
          </Box>
          <Box className="frogid-week-hero__cta">
            <Link href="https://apps.apple.com/au/app/frogid/id1176329797" target="_blank">
              <Image
                src="/app_store_badge_white.svg"
                alt=""
              />
              <Box className="sr-only">Download on the App Store</Box>
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=au.net.australianmuseum.frogid" target="_blank">
              <Image
                src="/google_play_badge.svg"
                alt=""
              />
              <Box className="sr-only">Get it on Google Play</Box>
            </Link>
          </Box>
          <Box className="frogid-week-hero__countdown">
            <FrogIDTimer startDate={startDate} endDate={endDate} />
          </Box>
          {/* <Box className="frogid-week-hero__message">
            <Box>FrogID Week has finished for 2020.</Box>
          </Box> */}
        </Box>
        <Box className="frogid-week-hero__key-img">
          {heroImage && <Image src={heroImage.fixed.src} alt="" />}
        </Box>
      </Container>
      <Box className="frogid-week-hero__bkg-img">
        {heroBkg && <Image src={heroBkg.fixed.src} alt="" />}
      </Box>
    </Box>
  );
};

export default Hero;

const FrogIDTimer: React.FC<{ startDate?: string, endDate?: string }> = ({ startDate, endDate }) => {
  if (startDate == null) return null;
  // Pass in a correct (upcoming) start and end date to display the counter, or 'on now' or 'over for this year'
  if (endDate && (moment() > moment(endDate))) {
    // Uncomment this to prepare for the end of frogid week 2024
    // return <Box className="counter__message">FrogID Week is&nbsp;over for this year&nbsp;</Box>;
    // Uncomment this to hide the message until next year, after the end of frogid week 2024
    return <Box className="counter__message"></Box>;
  }

  if (moment() > moment(startDate)) {
    return <Box className="counter__message">FrogID Week is&nbsp;on now&nbsp;</Box>;
  }

  return (
    <Box className="counter">
      <Box className="counter__text">Time until FrogID Week</Box>
      <Countdown renderer={renderer} date={startDate} />
    </Box>
  );
};

const renderer = ({ total, days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Box className="counter__message">Finished for 2022</Box>;
  }
  if (days < 1) {
    return (
      <Box className="counter__row">
        <Box as="span" className="counter__data">
          <Box as="span" className="counter__value">{hours}</Box> <Box as="span" className="counter__label">hours </Box>
        </Box>
        <Box as="span" className="counter__data">
          <Box as="span" className="counter__value">{minutes}</Box> <Box as="span" className="counter__label">minutes </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box className="counter__row">
        <Box as="span" className="counter__data">
         <Box as="span" className="counter__value">{days}</Box> <Box as="span" className="counter__label">days </Box>
        </Box>
        <Box as="span" className="counter__data">
          <Box as="span" className="counter__value">{hours}</Box> <Box as="span" className="counter__label">hours </Box>
        </Box>
        <Box as="span" className="counter__data">
          <Box as="span" className="counter__value">{minutes}</Box> <Box as="span" className="counter__label">minutes </Box>
        </Box>
      </Box>
    );
  }
};
